<template>
    <v-container fluid class="fill-height pa-0">
        <v-row justify="center">
            <v-col cols="12" sm="9" md="6" lg="4">

                <v-form v-model="isValid" ref="form">
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Neues Passwort vergeben
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">

                            <!-- Password forms should have (optionally hidden) username fields for accessibility -->
                            <v-text-field label="IK/Benutzername" :value="loginName"
                                          autocomplete="username" v-show="false" readonly />

                            <v-text-field label="Neues Passwort" v-model="pwdNeu"
                                          autocomplete="new-password"
                                          :rules="passwordRules"
                                          :type="pwTextNew ? 'text' : 'password'"
                                          :append-icon="pwTextNew ? 'mdi-eye-off' : 'mdi-eye'"
                                          @click:append="() => (pwTextNew = !pwTextNew)"
                                          error-count="5"
                                          clearable />

                            <v-text-field label="Passwort bestätigen" v-model="pwdBestaetigen"
                                          autocomplete="new-password"
                                          :rules="ReapeatpasswordRules"
                                          :type="pwTextRp ? 'text' : 'password'"
                                          :append-icon="pwTextRp ? 'mdi-eye-off' : 'mdi-eye'"
                                          @click:append="() => (pwTextRp = !pwTextRp)"
                                          clearable />

                            <verify-otp :guid="verCode" @verifyOtpDone="(rst) => { this.jwt = rst; }" 
                                        v-if="isUserValid && verCode" show-label />

                        </v-card-text>
                        <v-card-actions>
                            <v-btn :disabled="!isUserValid || waitingForCaptcha || jwt === null" color="primary"
                                    @click="resetPassword" :loading="waitingForCaptcha">

                                Speichern
                            </v-btn>
                            <v-btn color="primary" @click="doClear">
                                Abbrechen
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-form>

            </v-col>
        </v-row>

        <CaptchaFailDialog :isVisible="showDialogFailure" />

    </v-container>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue'
    import VerifyOtp from '@/components/VerifyOtp.vue'
    import eventBus from '@/main'

    export default {
        name: 'PasswortZuruecksetzen',

        components: {
            CaptchaFailDialog,
            VerifyOtp
        },

        watch: {
            pwdNeu() {
                this.isValid = this.verCode !== "" && this.pwdNeu === this.pwdBestaetigen;
                if (this.isValid) 
                    this.ReapeatpasswordRules = [];
                else
                    this.ReapeatpasswordRules = [
                        v => !!v || 'Bitte wiederholen Sie hier Ihr Passwort',
                        v => v == this.pwdNeu || 'Die beiden Passwörter müssen übereinstimmen'
                    ]

            },

            pwdBestaetigen() {
                this.isValid = this.verCode !== "" && this.pwdNeu === this.pwdBestaetigen;
            }
        },

        data() {
            return {
                isValid: true,
                showPasswort: false,
                pwdNeu: '',
                pwdBestaetigen: '',
                pwTextNew: false,
                pwTextRp: false,
                emailRules: [
                    v => !!v || 'Bitte geben Sie eine gültige Email-Adresse ein',
                    v => /.+@.+/.test(v) || 'Geben Sie bitte eine gültige Email-Adresse ein'
                ],
                passwordRules: [
                    v => !!v || 'Bitte geben Sie Ihr gewünschtes Passwort ein',
                    v => (v && v.length >= 10) || 'Das Passwort muss mindestens 10 Zeichen lang sein',
                    v => /(?=.*[A-Z])/.test(v) || 'Das Passwort muss mindestens einen Großbuchstaben beinhalten',
                    v => /(?=.*[a-z])/.test(v) || 'Das Passwort muss mindestens einen Kleinbuchstaben beinhalten',
                    v => /(?=.*\d)/.test(v) || 'Das Passwort muss mindestens eine Zahl beinhalten',
                    v => /([!@$%"&/\\()+=?#,.;*_-])/.test(v) || 'Das Passwort muss mindestens ein Sonderzeichen [!@$%"&/\\()+=?#,.;*_-] beinhalten'
                ],
                ReapeatpasswordRules: [
                    v => !!v || 'Bitte wiederholen Sie hier Ihr Passwort',
                    v => v == this.pwdNeu || 'Die beiden Passwörter müssen übereinstimmen'
                ],
                showDialogSuccess: false,
                showDialogFailure: false,
                active: false,
                waitingForCaptcha: false,
                verCode: '',
                loginName: null,
                verificationSuccess: false,
                jwt: null,
            }
        },
  
        computed: {
            isUserValid() {
                if (this.verCode && this.isValid && this.pwdNeu === this.pwdBestaetigen) {
                    return true;
                }
                return false;
            }
        },
 
        activated() {
            this.$refs.form.reset();
            this.verCode = this.$route.query.vercode;
            this.loginName = this.$route.query.LoginName;
        },

        methods: {

            passwordChangeSuccess() {

                let route = '/login';
                let user = this.$store.state.currentUser;

                if (user && user.leistungserbringer) {

                    /* Wenn in den Benutzerinfos ein LE-IK enthalten ist, 
                     * dann kann der Benutzer gleich angemeldet werden. 
                     */
                    eventBus.$emit('loginSuccessful');
                    eventBus.$emit('resetLogoutTimer', {});
                    route = '/';
                }

                this.$router.push(route);
            },

            async resetPassword() {
                this.waitingForCaptcha = true;

                // save the new password and login in the App
                this.$http.post('auth/execpasswordreset', {
                    Jwt: this.jwt,
                    LoginName: this.loginName,
                    TransaktionID: this.verCode,
                    PasswordNew: this.pwdNeu,

                }).then((response) => {
                    if (response.status === 200) {
                        this.$store.commit('updateCurrentUser', response.data);
                        this.waitingForCaptcha = false;
                        this.verificationSuccess = true;
                        this.pwdNeu = '';
                        this.pwdBestaetigen = '';
                        this.passwordChangeSuccess();
                    }
                }).catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message); // TODO dialog
                        this.waitingForCaptcha = false;
                    }
                });
            },

            doClear() {
                //clear the textboxes and go the the login window
                this.showDialog = false;

                this.pwdNeu = null;
                this.pwdBestaetigen = null;

                this.showPasswort = false;
                this.pwTextRp = false;
                this.pwTextNew = false;

                this.$refs.form.reset();
                this.$store.commit('updateCurrentUser', null);
                this.$router.push('/');
            }
        }
    }
</script>