<template>
    <v-container fluid class="fill-height pa-0">
        <v-row justify="center">
            <v-col cols="12" sm="10" md="8" lg="6">
                <v-form v-show="loginStep === 1" v-model="isLoginValid" ref="formCredentials">
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Anmeldung
                        </v-card-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="pink"
                                       v-bind="attrs"
                                       v-on="on"
                                       to="/faq"
                                       dark
                                       absolute
                                       top
                                       right
                                       fab>
                                    <v-icon>mdi-comment-question</v-icon>
                                </v-btn>
                            </template>
                            <span>Häufig gestellte Fragen</span>
                        </v-tooltip>
                        <!---->
                        <!--<v-list-item-title class="text-h6 mb-1" v-if="current">-->
                        <v-card-subtitle> Bitte melden Sie sich an, um Ihren Zwei-Faktor-Athentifizierungscode anzuzeigen!</v-card-subtitle>
                        <!--</v-list-item-title>-->
                        <v-card-text class="text-body-1 text--primary">
                            <v-text-field label="IK (9-stellig) / Benutzername" v-model="loginName" autocomplete="loginName" required
                                          :rules="[v => !!v || 'Bitte geben Sie Ihren Benutzernamen ein']" />

                            <v-text-field label="Passwort" v-model="password" autocomplete="current-password" required
                                          :type="showPasswort ? 'text' : 'password'"
                                          :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                          :rules="[v => !!v || 'Bitte geben Sie das Passwort ein']"
                                          @click:append="() => (showPasswort = !showPasswort)" />
                            <!-- User message -->
                            <v-alert border="left" :type="userMessageType" v-if="userMessage" class="mt-4">
                                {{ userMessage }}
                            </v-alert>

                        </v-card-text>
                        <v-card-actions>
                            <v-row dense>
                                <v-col cols="auto">
                                    <v-btn color="primary" type="submit"
                                           :disabled="!isLoginValid || loading" :loading="loading"
                                           @click.stop.prevent="validateCredentials">

                                        Weiter
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="flex-grow-1">
                                    <v-btn color="primary" type="button"
                                           :disabled="loading" to="/login">

                                        Abbrechen
                                    </v-btn>
                                </v-col>
                                <!--<v-col cols="auto" class="flex-grow-1">
                                    <v-btn color="primary" type="button"
                                           :disabled="loading" to="/registrierung">

                                        Registrieren
                                    </v-btn>
                                </v-col>-->
                                <!--<v-col cols="auto">
        <v-btn color="primary" type="button"
               class="px-1" text @click="openDialog">

            Passwort vergessen?
        </v-btn>
    </v-col>-->
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <v-form v-show="loginStep === 2" ref="form">
                    <v-card>
                        <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                            Authentifizierungs-App
                        </v-card-title>
                        <v-card-text class="text-body-1 text--primary">
                            <p class="text-justify text-hyphenate">
                                Durch Zwei-Faktor-Authentifizierung wird die Sicherheit Ihres Benutzerkontos verbessert.
                                Verwenden Sie auf Ihrem Mobilgerät (Handy/Tablet) eine Authentifizierungs-App, um einen Sicherheitscode zu erstellen,
                                der bei der Anmeldung neben Ihren bekannten Zugangsdaten angefordert wird.
                            </p>
                            <ol class="text-justify text-hyphenate pl-4">
                                <li>
                                    <p>
                                        Öffnen Sie ihre Authentifizierungs-App.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Fügen Sie in der App ein Konto hinzu bzw. aktualisieren Sie ihr bestehendes Konto
                                        und scannen Sie den unten stehenden QR-Code.
                                    </p>
                                    <v-row class="mb-3">
                                        <v-col cols="12" sm="auto" class="text-center">
                                            <qrcode-vue :value="otpauthLink" size="200" level="H" />
                                        </v-col>
                                        <v-col>
                                            <p>
                                                Sollten Sie den QR-Code nicht scannen können, dann geben Sie
                                                in der App bitte folgenden Schlüssel ein:
                                            </p>
                                            <code class="d-inline-flex font-weight-bold align-center text-left pa-0">
                                                <span class="ml-2 mr-1 py-1">
                                                    {{ secret && secret.replace(/(.{4})/g, '$1 ').trim() }}
                                                </span>
                                                <v-btn color="black" type="button"
                                                       @click.stop.prevent="shareSecret2FA"
                                                       icon tile text>

                                                    <v-icon>mdi-content-copy</v-icon>
                                                </v-btn>
                                                <a id="otpauthLinkOpener" :href="otpauthLink" class="d-none" />
                                            </code>
                                            <p v-show="copyClipboardSuccess" class="text-body-2 red--text">
                                                Schlüssel in Zwischenablage kopiert
                                            </p>
                                        </v-col>
                                    </v-row>
                                </li>
                                <li>
                                    <p>
                                        Nachdem das Konto eingerichtet ist, geben Sie bitte den von der App erstellten Code ein
                                        und bestätigen den Vorgang durch Angabe Ihres aktuellen Passwortes:
                                    </p>
                                    <v-row>
                                        <v-col cols="12" sm="auto">
                                            <verify-otp :secret="secret" class="d-inline-flex" show-label
                                                        @verifyOtpDone="(rst) => { this.isOtpValid = true; }" />
                                        </v-col>
                                        <v-col cols="12" sm="auto">
                                            <v-text-field v-if="!current"
                                                          label="Passwort" v-model="password"
                                                          autocomplete="current-password"
                                                          :type="showPasswort ? 'text' : 'password'"
                                                          :rules="[v => !!v || 'Bitte geben Sie das Passwort ein']"
                                                          :append-icon="showPasswort ? 'mdi-eye-off' : 'mdi-eye'"
                                                          @click:append="() => (showPasswort = !showPasswort)"
                                                          class="d-inline-flex mr-4" required />
                                        </v-col>
                                    </v-row>

                                </li>
                            </ol>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn :disabled="!isValid" color="primary"
                                   @click="resetAuthenticator" :loading="loading">

                                Speichern
                            </v-btn>
                            <v-btn color="primary" @click="clear">
                                Abbrechen
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-form>

            </v-col>
        </v-row>

        <!-- Dialog Fehler -->
        <v-dialog v-model="showDialogFailure">
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>$vuetify.icons.error</v-icon>
                    Aktualisierung fehlgeschlagen
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-alert class="mt-4 mb-0" type="error">
                        {{ errorMessage }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" type="button" @click="showDialogFailure = false;">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <CaptchaFailDialog :isVisible="showDialogCaptchaFailure" />

    </v-container>
</template>

<script>
    import CaptchaFailDialog from '@/components/CaptchaFailDialog.vue';
    import VerifyOtp from '@/components/VerifyOtp.vue';
    import eventBus from '@/main';
    import QrcodeVue from 'qrcode.vue'

    export default {
        name: 'AuthenticatorReset',

        components: {
            CaptchaFailDialog,
            VerifyOtp,
            QrcodeVue,
        },

        data() {
            return {
                verCode: null,
                loginName: null,
                leistungserbringer: null,
                secret: null,
                password: null,
                errorMessage: null,
                showPasswort: false,
                isOtpValid: false,
                loading: false,
                showDialogFailure: false,
                showDialogCaptchaFailure: false,
                copyClipboardSuccess: false,
                otpauthSchemeHandlerDetected: false,
                loginStep: 1,
                isLoginValid: false,
                userMessage: '',
                userMessageType: '',
                current: false
            }
        },

        computed: {
            otpauthLink: function () {

                let label = this.leistungserbringer;
                let secret = this.secret;
                let issuer = 'Service-Portal%20f%C3%BCr%20Apotheken';

                //z.B. Service-Portal für Apotheken (IK 123456789)
                return `otpauth://totp/IK%20${label}?secret=${secret}&issuer=${issuer}`;
            },

            isValid() {
                return this.verCode != null && this.leistungserbringer != null
                    && this.secret != null && this.loginName != null && this.password != null && this.isOtpValid;
            },
        },
 
        activated() {

            console.log("activated");
            this.$nextTick(async () => {
                this.$refs.form.reset();
                this.verCode = this.$route.query.vercode;
                this.loginName = this.$route.query.loginname;
                this.current = this.current = this.$route.query.current === '1' ? true : false;


                if (!this.current) { 
                try {
                    let rsp = await this.$http.get('auth/fetchPreRegistrationData', {
                        params: {
                            guid: this.verCode,
                            current: this.current
}
                    });

                    this.leistungserbringer = rsp.data.leistungserbringer;
                    this.secret = rsp.data.secret2FA;
                    this.loginStep = 2;
                }
                catch (err) {
                    this.errorMessage = err.response.data.message;
                    this.showDialogFailure = true;
                    }
                }
            });
        },

        methods: {

            async validateCredentials() {
                try {
                    await this.$recaptchaLoaded();
                    const token = await this.$recaptcha('authenticatorreset');

                    this.loading = true;
                    let response = await this.$http.post('auth/verifylogincredentials', {
                        Token: token,
                        Username: this.loginName,
                        Password: this.password
                    });

                    if (response.status === 200) {
                        this.jwt1FA = response.data;
                        console.log('this.jwt1FA', response.data);
                        let rsp = await this.$http.get('auth/fetchPreRegistrationData', {
                            params: {
                                guid: this.verCode,
                                current: this.current
                            }
                        });

                        this.leistungserbringer = rsp.data.leistungserbringer;
                        this.secret = rsp.data.secret2FA;
                        this.loginStep = 2;

                        //console.log('this.loginStep', this.loginStep);
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.userMessage = error.response.data.message;
                        this.userMessageType = 'error';
                    }
                }
                finally {
                    this.loading = false;
                }
            },


            async resetAuthenticator() {

                try {
                    // get captcha token from Google
                    await this.$recaptchaLoaded();
                    const token = await this.$recaptcha('authenticatorreset');

                    this.loading = true;

                    let credRsp = await this.$http.post('auth/verifylogincredentials', {
                        Token: token,
                        Username: this.loginName,
                        Password: this.password,
                    });

                    let authRsp = await this.$http.post('auth/execauthenticatorreset', {
                        Jwt: credRsp.data,
                        TransaktionID: this.verCode,
                        SecretNew: this.secret,
                    });

                    let user = authRsp.data;
                    let route = '/login';

                    if (user && user.leistungserbringer) {

                        /* Wenn in den Benutzerinfos ein LE-IK enthalten ist,
                         * dann kann der Benutzer gleich angemeldet werden.
                         */
                        //await this.$root.$refs.login.getModule();
                        eventBus.$emit('moduleSelected');
                        this.$store.commit('updateCurrentUser', user);
                        eventBus.$emit('loginSuccessful');
                        eventBus.$emit('resetLogoutTimer');
                        route = '/';
                    }

                    this.$router.push(route);
                }
                catch (err) {
                    this.errorMessage = err.response.data.message;
                    this.showDialogFailure = true;
                }
                finally {
                    this.loading = false;
                }
            },

            async shareSecret2FA() {

                let timeout = 1000;
                let ctx = this;

                let copyToClipboard = function () {
                    navigator.clipboard.writeText(ctx.secret);
                    ctx.copyClipboardSuccess = true;
                };

                let onBlur = function () {
                    ctx.otpauthSchemeHandlerDetected = true;
                };

                let onVisibilityChange = function (e) {
                    if (e.target.visibilityState === 'hidden') {
                        ctx.otpauthSchemeHandlerDetected = true;
                    }
                };

                if (!ctx.otpauthSchemeHandlerDetected) {

                    window.addEventListener('blur', onBlur);
                    document.addEventListener('visibilitychange', onVisibilityChange);

                    setTimeout(function () {

                        if (!ctx.otpauthSchemeHandlerDetected) {
                            copyToClipboard();
                        }

                        window.removeEventListener('blur', onBlur);
                        document.removeEventListener('visibilitychange', onVisibilityChange);

                    }, timeout);
                }

                ctx.copyClipboardSuccess = false;
                document.getElementById('otpauthLinkOpener').click();
            },

            clear() {
                this.$refs.form.reset();
                this.$store.commit('updateCurrentUser', null);
                this.$router.push('/login');
                this.leistungserbringer = null;
                this.password = null;
                this.secret = '';
                this.loginStep = 1;
            },

        }
    }
</script>